@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Montserrat';
}

.center {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.center>* {
  display: block;
  margin: auto;
  text-align: center;
}

.verticalCenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.noSelect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input,
textarea {
  outline: none;
  padding-left: 10px;
  resize: none;
  font-family: "Roboto";
  border: none;
  -moz-appearance: textfield; 
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: 0px;
  font-family: 'Roboto';
  text-align: center;
}